import React, { useState } from "react";
import "./styles.css";
import { PlanItem } from "./components/PlanItem";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";

export const Plans = ({ title, chessPlans }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="plans_container" onClick={() => setIsExpanded(!isExpanded)}>
      <div className="plans_main">
        <div className="plans_title">{title}</div>
        <div
          className={
            "plans_chevron" + (isExpanded ? " plans_chevron_expanded" : "")
          }
        >
          <Chevron width="20" height="20" />
        </div>
      </div>
      <ul
        className={"plans_list"}
        style={{ height: isExpanded ? "auto" : "0" }}
      >
        {chessPlans.map((item, index) => (
          <PlanItem isExpanded={isExpanded} item={item} index={index} />
        ))}
      </ul>
    </div>
  );
};
