import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

export const AuthHoc = ({ children }) => {
  const PRIVATE_ROUTES = {
    none: "",
    main: "/",
    test: "/lol",
  };
  const AUTH_ROUTES = {
    signIn: "/signin",
    signUp: "/signup",
  };
  const [cookies, setCookie] = useCookies(["userToken"]);
  const params = useLocation();
  const navigate = useNavigate();
  const userToken = cookies.userToken;
  const isPrivate = Object.values(PRIVATE_ROUTES).includes(
    params.pathname.toLowerCase(),
  );
  const isAuthRoute = Object.values(AUTH_ROUTES).includes(
    params.pathname.toLowerCase(),
  );
  const isAuth = !!userToken;
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    setShouldRender(true);
    if (!isAuth && isPrivate) {
      navigate("/signin");
    }
    if (isAuth && isAuthRoute) {
      navigate("/");
    }
  });

  if (shouldRender) {
    return children;
  }
};
