import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "../utils";
import { API_ENDPOINTS } from "../constants/common.ts";

const UserActionTypes = {
  USER_SIGN_IN: "user/signIn",
  USER_SIGN_UP: "user/signUp",
};

export const UserActionCreator = {
  userSignIn: createAsyncThunk(
    UserActionTypes.USER_SIGN_IN,
    async ({ email, password }) => {
      const result = await apiClient.post(API_ENDPOINTS.userSignIn, {
        email,
        password,
      });

      return result.data;
    },
  ),
  userSignUp: createAsyncThunk(
    UserActionTypes.USER_SIGN_UP,
    async ({ email, name, password }) => {
      const result = await apiClient.post(API_ENDPOINTS.userSignUp, {
        email,
        name,
        password,
      });

      return result.data;
    },
  ),
};
