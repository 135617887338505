import React, {useEffect, useState} from "react";
import "./styles.css";
import { Header } from "../../components/Header";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";
import { useForm } from "react-hook-form";
import { REGEXES } from "../../constants/common";
import {RootState} from "../../store";
import {UserActionCreator} from "../../store/userActions";
import {connect} from "react-redux";
import {selectUserError, selectUserErrorMessage, selectUserPending} from "../../store/selectors";

export const _SignUp = ({
                          signUpRequest,
                            pending,
                            error,
                            errorMessage,
                        }) => {
  const [registrationData, setRegistrationData] = useState();
  const {
    register,
    handleSubmit,
    watch,
      setError,
    formState: { errors },
  } = useForm({mode: "onChange"});

  const emailError = errors.email?.message;
  const nameError = errors.name?.message;
  const passwordError = errors.password?.message;
  const checkPasswordError = errors.checkPassword?.message;
  const onSubmit = (data) => {
    if (data.password !== data.checkPassword) {
      setError('checkPassword', { message: 'пароли не совпадают'});
    }
    if (data.password === data.checkPassword) {
      signUpRequest(data);
    }
  }
    useEffect(() => {
        setError('email', { message: errorMessage })
    }, [errorMessage, error]);

  return (
    <>
      <Header />
      <div className="sign-up_container">
        <h2>Регистрация</h2>
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="sign-up_form-container"
        >
          <div className="sign-up_input-item">
            <Input
                register={register("email", {
                  required: {message: 'введите e-mail', value: true},
                  pattern: {message: 'некорректный e-mail', value: REGEXES.email}
                })}
                name="areaWorth"
                type="email"
                placeholder="e-mail"
                label="введите e-mail"
                error={!!emailError}
                errorMessage={emailError}
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("name", {
                  required: {message: 'введите имя', value: true}
                })}
                name="areaWorth"
                type="text"
                placeholder="имя"
                label="введите ваше имя"
                error={!!nameError}
                errorMessage={nameError}
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("password", {
                  required: {message: 'введите пароль', value: true},
                  pattern: {message: 'некорректный пароль', value: REGEXES.password}
                })}
                name="areaWorth"
                type="password"
                placeholder="пароль"
                label="введите пароль"
                error={!!passwordError}
                errorMessage={passwordError}
            />
          </div>
          <div className="sign-up_input-item">
            <Input
                register={register("checkPassword", {
                  required: {message: 'введите пароль', value: true},
                  pattern: {message: 'некорректный пароль', value: REGEXES.password}
                })}
                name="areaWorth"
                type="password"
                placeholder="пароль"
                label="повторите пароль"
                error={!!checkPasswordError}
                errorMessage={checkPasswordError}
            />
          </div>
          <div className="sign-up_input-item">
            <Button pending={pending} text="регистрация"/>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
    pending: selectUserPending(state),
    error: selectUserError(state),
    errorMessage: selectUserErrorMessage(state),
});

const mapDispatchToProps = {
  signUpRequest: UserActionCreator.userSignUp,
};

export const SignUp = connect(mapStateToProps, mapDispatchToProps)(_SignUp);
