import React, { useState, useEffect } from "react";
import "./styles.css";
import { Header } from "../../components/Header";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import { UserActionCreator } from "../../store/userActions";
import { RootState } from "../../store";
import { connect } from "react-redux";
import {
  selectIsAuth,
  selectUserError,
  selectUserPending,
  selectUserToken,
} from "../../store/selectors";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { REGEXES } from "../../constants/common";

export const _SignIn = ({ pending, error, isAuth, signInRequest, token }) => {
  const [cookies, setCookie] = useCookies(["userToken"]);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const emailError = errors.email?.message;
  const passwordError = errors.password?.message;

  useEffect(() => {
    if (token) {
      setCookie("userToken", token, { path: "/" });
    }
  }, [token]);
  const onSubmit = (data) => {
    signInRequest(data);
  }
  return (
    <>
      <Header />
      <div className="sign-in_container">
        <h2>Авторизация</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="sign-up_form-container"
        >
          <div className="sign-in_input-item">
            <Input
              register={register("email", {
                required: { message: "введите e-mail", value: true },
                pattern: {
                  message: "некорректный e-mail",
                  value: REGEXES.email,
                },
              })}
              name="areaWorth"
              type="email"
              placeholder="e-mail"
              label="введите e-mail"
              error={!!emailError}
              errorMessage={emailError}
            />
          </div>
          <div className="sign-in_input-item">
            <Input
              register={register("password", {
                required: { message: "введите пароль", value: true },
                pattern: {
                  message: "некорректный пароль",
                  value: REGEXES.password,
                },
              })}
              name="areaWorth"
              type="password"
              placeholder="пароль"
              label="введите пароль"
              error={!!passwordError}
              errorMessage={passwordError}
            />
          </div>
          <div className="sign-in_input-item">
            Нет аккаунта?{" "}
            <Link className="sign-in_link" to="/signup/">
              Зарегистрируйся
            </Link>
          </div>
          <div className="sign-in_input-item">
            <Button pending={pending} text="войти" />
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  pending: selectUserPending(state),
  error: selectUserError(state),
  isAuth: selectIsAuth(state),
  token: selectUserToken(state),
});

const mapDispatchToProps = {
  signInRequest: UserActionCreator.userSignIn,
};

export const SignIn = connect(mapStateToProps, mapDispatchToProps)(_SignIn);
