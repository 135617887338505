import React from "react";
import "./styles.css";

export const Input = ({
  error,
  errorMessage,
  register,
  value,
  onChange,
  name,
  type,
  placeholder,
  label,
}) => {
  return (
    <>
        {label && (
            <div className="input_label">{label}</div>
        )}
        <input
            className={"input_main" + (error ? " input_error" : "")}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...register}
      />
      {errorMessage && (
        <div className="input_error-message">{errorMessage}</div>
      )}
    </>
  );
};
