import "./App.css";
import { Realty } from "./Realty";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { SignUp } from "./Realty/pages/SignUp";
import { SignIn } from "./Realty/pages/SignIn";
import { realtyStore } from "./Realty/store";
import { CookiesProvider } from "react-cookie";
import { AuthHoc } from "./Realty/components/hocs/AuthHoc";
import { HousingComplexShared } from "./Realty/pages/Shared/components/HousingComplexShared";

function App() {
  return (
    <>
      <CookiesProvider>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Provider store={realtyStore}>
          <BrowserRouter>
            <AuthHoc>
              <Routes>
                <Route path="/" element={<Realty />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route
                  path="/shared/housing/:id/:installmentPlan/:"
                  element={<HousingComplexShared />}
                />
              </Routes>
            </AuthHoc>
          </BrowserRouter>
        </Provider>
      </CookiesProvider>
    </>
  );
}

export default App;
