import React from "react";
import { ReactComponent as DefaultImage } from "../../../assets/building.svg";
import "./styles.css";

export const HousingItem = ({ housing, onClick, key }) => {
  return (
    <li className="housing_item-container" onClick={onClick} key={key}>
      <div className="housing_item-image-container">
        {housing.images[0] ? (
          <div className="housing_item-image">
            <img src={housing.images[0]} width="100%" />
          </div>
        ) : (
          <div className="housing_item-default-image">
            <DefaultImage width="100%" height="100%" />
          </div>
        )}
      </div>
      <div className="housing_item-info">
        <div className="housing_item-title">{housing.housingName}</div>
        <div className="housing_item-builder">{housing.builder}</div>
        <div className="housing_item-address">{housing.address}</div>
      </div>
    </li>
  );
};
