import React from "react";
import "./styles.css";
import { Skeleton } from "../../../../Skeleton";

export const ByPeriodSkeleton = () => {
  return (
    <>
      <Skeleton className="by-period-skeleton_block" />
      <Skeleton className="by-period-skeleton_block" />
      <Skeleton className="by-period-skeleton_block" />
    </>
  );
};
