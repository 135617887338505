export const REGEXES = {
  password: /^[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]{6,}$/,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
}

export const API_ENDPOINTS = {
  flatsTable: "https://mgmdk.ru/bot/api/realty/index.php",
  housings: "/bot/api/realty/housings.php?getHousings",
  installmentPlanTypes: "/bot/api/realty/housings.php?getInstallmentPlanTypes",
  housingPricesByFirstPayment:
    "/bot/api/realty/prices.php?byFirstPayment",
  housingPricesByPeriod: "/bot/api/realty/prices.php?byPeriod",
  userSignIn: "https://husamhelper.ru/api/v1/auth/login/",
  userSignUp: "https://husamhelper.ru/api/v1/auth/register",
};

export const INSTALLMENT_PLANS_LIB = {
  full: {
    name: "full",
    label: "полная стоимость",
  },
  without: {
    name: "without",
    label: "без взноса",
  },
};
