import React from "react";
import "./styles.css";
import { Skeleton } from "../../../../Skeleton";

export const ByFirstPaymentSkeleton = () => {
  return (
    <>
      <Skeleton className="by-first-payment-skeleton_block" />
      <Skeleton className="by-first-payment-skeleton_block" />
      <Skeleton className="by-first-payment-skeleton_block" />
    </>
  );
};
