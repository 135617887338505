import React, { useEffect, useState } from "react";
import "./styles.css";
import { HousingsActionCreator } from "../../store/housingsActions.ts";
import { RootState } from "../../store/index.ts";
import {
  selectHousingsList,
  selectHousingsPending,
} from "../../store/selectors.ts";
import { connect } from "react-redux";
import { HousingItem } from "./HousingItem";
import { HousingsSkeleton } from "./HousingsSkeleton";
import { Search } from "../../components/Search";

export const _Housings = ({
  pending,
  housings,
  fetchHousings,
  setSelectedHousing,
}) => {
  const [searchResults, setSearchResults] = useState();
  const housingArray = searchResults || housings;
  useEffect(() => {
    fetchHousings();
  }, []);

  if (pending) {
    return <HousingsSkeleton />;
  }

  return (
    <>
      <div className="housings-search-bar">
        <Search onResult={setSearchResults} housings={housings} />
      </div>
      <ul className="housings-list">
        {housingArray.map((housing) => (
          <HousingItem
            key={housing.id}
            housing={housing}
            onClick={() => setSelectedHousing(housing)}
          />
        ))}
      </ul>
    </>
  );
};

const mapDispatchToProps = {
  fetchFlatsTable: HousingsActionCreator.fetchFlatsTable,
  fetchHousings: HousingsActionCreator.fetchHousings,
};

const mapStateToProps = (state: RootState) => ({
  pending: selectHousingsPending(state),
  housings: selectHousingsList(state),
});

export const Housings = connect(mapStateToProps, mapDispatchToProps)(_Housings);
