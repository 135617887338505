import React from "react";
import "./styles.css";
import { TabsPropsType } from "./types.ts";

export const Tabs = ({ activeTab, setActiveTab, tabs }: TabsPropsType) => {
  return (
    <>
      <ul className="tabs_container">
        {Object.values(tabs).map((tab) => (
          <li
            onClick={() => setActiveTab(tab.id)}
            className={
              "tabs_item " + (tab.id === activeTab ? "tabs_active" : "")
            }
          >
            {tab.name}
          </li>
        ))}
      </ul>
    </>
  );
};
