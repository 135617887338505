import React from "react";
import "./styles.css";
import { ReactComponent as Logo } from "../../assets/logo_horizontal_color.svg";
import { Link } from "react-router-dom";

export const Header = ({}) => {
  return (
    <Link to="/">
      <div className="header_container">
        <div className="header_logo">
          <Logo width="100%" height="100%" />
        </div>
      </div>
    </Link>
  );
};
