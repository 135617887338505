import React from "react";
import "./styles.css";
import { ReactComponent as Loader } from "../../../assets/loading_icon.svg";
import {getButtonContent} from "./utils";

export const Button = ({ icon, iconOnly, text, iconLeft, iconRight, onClick, pending }) => {
  const content = getButtonContent(icon, text, iconOnly);
  return (
    <button onClick={onClick} className="button_main">
      {pending ? <Loader height="18px" width="18px" /> : content}
    </button>
  );
};
