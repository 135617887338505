import { configureStore, createStore } from "@reduxjs/toolkit";
import { commonReducer } from "./commonReducer.ts";
// ...

export const store = () =>
  configureStore({
    reducer: commonReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: { name: "@Realty" },
  });
export const realtyStore = store();
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
