import React from "react";
import { Skeleton } from "../../../../components/common/Skeleton";
import { ReactComponent as DefaultImage } from "../../../../assets/building.svg";
import "./styles.css";

export const HousingSkeletonItem = () => (
  <li className="housing_skeleton_item-container">
    <div className="housing_skeleton_item-image">
      <DefaultImage width="100%" height="100%" />
    </div>
    <Skeleton className="housing_skeleton_item-title" />
    <Skeleton className="housing_skeleton_item-builder" />
    <Skeleton className="housing_skeleton_item-address" />
  </li>
);
