export const getClippedText = (text, number) => {
  const textArray = text && text.split(" ");
  let clippedTextArray = [];
  if (number && textArray.length > number) {
    textArray.map((word: string) => word && clippedTextArray.push(word));
    const clippedText = clippedTextArray.slice(0, number).join(" ");
    return {
      clippedText,
      textLength: clippedTextArray.length,
    };
  }
  return {
    clippedText: text,
    textLength: number,
  };
};
