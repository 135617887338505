import { RootState } from "./index.ts";

export const selectHousingsReducer = (state: RootState) =>
  state.housingsReducer;

export const selectHousings = (state: RootState) =>
  selectHousingsReducer(state)?.housings;
export const selectHousingsPending = (state: RootState) =>
  selectHousings(state)?.pending;
export const selectHousingsError = (state: RootState) =>
  selectHousings(state)?.error;
export const selectHousingsList = (state: RootState) =>
  selectHousings(state)?.data;

export const selectInstallmentPlanTypes = (state: RootState) =>
  selectHousingsReducer(state)?.installmentPlanTypes;
export const selectInstallmentPlanTypesPending = (state: RootState) =>
  selectInstallmentPlanTypes(state)?.pending;
export const selectInstallmentPlanTypesError = (state: RootState) =>
  selectInstallmentPlanTypes(state)?.error;
export const selectInstallmentPlanTypesList = (state: RootState) =>
  selectInstallmentPlanTypes(state)?.data;

export const selectPricesByFirstPayment = (state: RootState) =>
  selectHousingsReducer(state)?.housingPricesByFirstPayment;
export const selectPricesByFirstPaymentPending = (state: RootState) =>
  selectPricesByFirstPayment(state)?.pending;
export const selectPricesByFirstPaymentError = (state: RootState) =>
  selectPricesByFirstPayment(state)?.error;
export const selectPricesByFirstPaymentData = (state: RootState) =>
  selectPricesByFirstPayment(state)?.data;
export const selectRequiredPayment = (state: RootState) =>
  selectPricesByFirstPaymentData(state)?.requiredPayment;

export const selectPricesByPeriod = (state: RootState) =>
  selectHousingsReducer(state)?.housingPricesByPeriod;
export const selectPricesByPeriodPending = (state: RootState) =>
  selectPricesByPeriod(state)?.pending;
export const selectPricesByPeriodError = (state: RootState) =>
  selectPricesByPeriod(state)?.error;
export const selectPricesByPeriodData = (state: RootState) =>
  selectPricesByPeriod(state)?.data;

export const selectUserReducer = (state: RootState) => state.userReducer;

export const selectUserPending = (state: RootState) =>
  selectUserReducer(state).pending;
export const selectUserError = (state: RootState) =>
  selectUserReducer(state).error;
export const selectUserErrorMessage = (state: RootState) =>
    selectUserReducer(state).errorMessage;
export const selectUserData = (state: RootState) =>
  selectUserReducer(state).userData;
export const selectIsAuth = (state: RootState) => !!selectUserData(state).token;
export const selectUserToken = (state: RootState) =>
  selectUserData(state).token;
