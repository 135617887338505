import React from "react";
import { ReactComponent as PlanIcon } from "../../../../../../assets/icons/floor_plan.svg";

export const PlanItem = ({ isExpanded, item, index }) => {
  const handleClickPlan = (src: string) => {
    window.open(src, "_blank");
  };

  return (
    <li
      className={"plans_item" + (isExpanded ? " item_expanded" : "")}
      onClick={() => handleClickPlan(item.src)}
      style={
        !isExpanded && index !== 0 ? { marginTop: "-76px", height: "76px" } : {}
      }
    >
      <div className="plans_icon">
        <PlanIcon fill="white" />
      </div>
      <div>{item.name}</div>
    </li>
  );
};
