import React from "react";
import { HousingSkeletonItem } from "./HousingSkeletonItem";
import "./styles.css";

export const HousingsSkeleton = () => {
  return (
    <ul className="housings-list">
      {Array.from({ length: 6 }, () => (
        <HousingSkeletonItem />
      ))}
    </ul>
  );
};
