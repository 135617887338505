import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "./styles.css";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Plans } from "./components/Plans";
import { InstallmentPlan } from "../../../../components/common/InstallmentPlan";
import { RootState } from "../../../../store/index.ts";
import { HousingsActionCreator } from "../../../../store/housingsActions.ts";
import { connect } from "react-redux";
import { selectHousingsReducer } from "../../../../store/selectors.ts";
import { getClippedText } from "./utils.ts";

export const _HousingComplexShared = ({ housing }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const CLIPPED_TEXT_LENGTH = 10;
  const { clippedText, textLength } = getClippedText(
    housing.description,
    CLIPPED_TEXT_LENGTH,
  );
  const canBeClipped = textLength && textLength > CLIPPED_TEXT_LENGTH;
  const description = isDescriptionExpanded ? housing.description : clippedText;

  return (
    <div className="housing_complex_container">
      <div className="housing_complex_title">{housing.housingName}</div>
      <div className="housing_complex_info">
        <div>застройщик: {housing.builder || "нет информации"}</div>
        <div>дата сдачи: {housing.deadline || "нет информации"}</div>
      </div>
      <div className="housing_complex_swyper">
        {housing.images.length > 0 ? (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            style={{
              "--swiper-pagination-color": "#FFC800",
              "--swiper-navigation-color": "#FFC800",
            }}
          >
            {housing.images.map((image, index) => (
              <SwiperSlide>
                <img src={image} width="100%" alt={`image ${index}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <>нет фотографий</>
        )}
      </div>
      <div
        className="housing_complex_description"
        onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
      >
        {description || "нет описания"}
        {!isDescriptionExpanded && canBeClipped && <b> подробнее...</b>}
      </div>
      {housing.chessPlans.length > 0 && (
        <Plans title="Шахматки" chessPlans={housing.chessPlans} />
      )}
      {housing.layouts.length > 0 && (
        <Plans title="Планировки" chessPlans={housing.layouts} />
      )}
      <InstallmentPlan housing={housing} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  main: selectHousingsReducer(state),
});

const mapDispatchToProps = {
  fetchFlatsTable: HousingsActionCreator.fetchFlatsTable,
  fetchHousings: HousingsActionCreator.fetchHousings,
};

export const HousingComplexShared = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_HousingComplexShared);
