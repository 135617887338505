import {Input} from "../../../../components/common/Input";
import {Button} from "../../../../components/common/Button";
import { ReactComponent as ShareIcon } from "../../../../assets/icons/share.svg";
import React from "react";
import './styles.css';
import {useLocation, useParams, useSearchParams} from "react-router-dom";

export const ShareUSP = ({
    shareData,
                         }) => {
    const path = window?.location;
    let url = '';

    if (!shareData.fullPrice) {
        return null;
    }

    Object.values(shareData).map(item => url += `${item}/`);
    console.log(url)
    const shareDataObject = {
        title: shareData.housingName,
        text: "Только посмотри, что для тебя подобрали!",
        url,
    };

    const handleShare = async () => {
        try {
            await navigator.share(shareDataObject);
        } catch (err) {
            console.log("error");
        }
    };

    return (
        <div className="share-block_container">
            <Input value={`${path}${url}`} />
            <Button
                icon={<ShareIcon height="18px" width="18px"/>}
                iconOnly
                onClick={handleShare}
            />
        </div>
    );
}
