import React, { useEffect, useState } from "react";
import "./styles.css";
import { Tabs } from "../Tabs";
import { ByFirstPayment } from "./ByFirstPayment";
import { ByPeriod } from "./ByPeriod";
import { INSTALLMENT_PLAN_TYPES } from "./constants.ts";
import { RootState } from "../../../store/index.ts";
import {
  selectInstallmentPlanTypesList,
  selectInstallmentPlanTypesPending,
} from "../../../store/selectors.ts";
import { HousingsActionCreator } from "../../../store/housingsActions.ts";
import { connect } from "react-redux";
import { InstallmentPlanSkeleton } from "./InstallmentPlanSkeleton";

export const _InstallmentPlan = ({
  housing,
  installmentPlans,
  pending,
  fetchInstallmentPlanTypes,
    setShareData,
}) => {
  const [option, setOption] = useState();

  const hasMultiTypes =
    installmentPlans && Object.keys(installmentPlans)?.length > 1;

  useEffect(() => {
    fetchInstallmentPlanTypes(housing.housingName);
  }, []);

  useEffect(() => {
    if (!!installmentPlans && hasMultiTypes) {
      setOption(INSTALLMENT_PLAN_TYPES.byFirstPayment.id);
    }
    if (!!installmentPlans && !hasMultiTypes) {
      setOption(INSTALLMENT_PLAN_TYPES[Object.keys(installmentPlans)]?.id);
    }
  }, [installmentPlans]);

  if (pending) {
    return <InstallmentPlanSkeleton />;
  }

  return (
    <>
      <div className="installment_title">
        Рассрочка {INSTALLMENT_PLAN_TYPES[option]?.name}
      </div>
      {hasMultiTypes && (
        <Tabs
          activeTab={option}
          setActiveTab={setOption}
          tabs={installmentPlans}
        />
      )}
      {option === INSTALLMENT_PLAN_TYPES.byFirstPayment.id && (
        <ByFirstPayment housingName={housing.housingName} setShareData={setShareData} />
      )}
      {option === INSTALLMENT_PLAN_TYPES.byPeriod.id && (
        <ByPeriod housingName={housing.housingName} setShareData={setShareData} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  installmentPlans: selectInstallmentPlanTypesList(state),
  pending: selectInstallmentPlanTypesPending(state),
});

const mapDispatchToProps = {
  fetchInstallmentPlanTypes: HousingsActionCreator.fetchInstallmentPlanTypes,
};

export const InstallmentPlan = connect(
  mapStateToProps,
  mapDispatchToProps,
)(_InstallmentPlan);
