import { createReducer } from "@reduxjs/toolkit";
import { UserActionCreator } from "./userActions.ts";

const initialState = {
  pending: false,
  error: false,
  errorMessage: null,
  userData: {
    id: null,
    email: null,
    name: null,
    token: null,
  },
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(UserActionCreator.userSignIn.pending, (state) => {
      state.pending = true;
      state.error = false;
      state.errorMessage = null;
    })
    .addCase(UserActionCreator.userSignIn.fulfilled, (state, action) => {
      state.pending = false;
      state.error = false;
      state.userData = action.payload;
    })
    .addCase(UserActionCreator.userSignIn.rejected, (state) => {
      state.pending = false;
      state.error = true;
    })
    .addCase(UserActionCreator.userSignUp.pending, (state) => {
      state.pending = true;
      state.error = false;
      state.errorMessage = null;
    })
    .addCase(UserActionCreator.userSignUp.fulfilled, (state, action) => {
      state.pending = false;
      state.error = false;
      state.userData = action.payload;
    })
    .addCase(UserActionCreator.userSignUp.rejected, (state, action) => {
      state.pending = false;
      state.error = true;
      state.errorMessage = action.error.message;
    });
});
