import React, { useEffect, useState } from "react";
import "./styles.css";
import { Input } from "../common/Input";
import { searchHousing } from "./utils.ts";

export const Search = ({ housings, onResult }) => {
  const [target, setTarget] = useState("");

  useEffect(() => {
    if (target) {
      onResult(searchHousing(housings, target));
    } else {
      onResult(housings);
    }
  }, [target]);

  return (
    <>
      <Input
        value={target}
        onChange={setTarget}
        placeholder="поиск по названию"
      />
    </>
  );
};
